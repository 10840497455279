import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
export default function Mobilefullscreen(props) {
    const enterFullScreen = () => {
        // const elem = document.documentElement; // You can also target a specific element
        // if (elem.requestFullscreen) {
        //   elem.requestFullscreen().then(() => {
        //     props.handleClose();
        //   });
        
        // } else if (elem.mozRequestFullScreen) { // Firefox
        //   elem.mozRequestFullScreen().then(() => {
        //     props.handleClose();
        //   });
        // } else if (elem.webkitRequestFullscreen) { // Chrome, Safari and Opera
        //   elem.webkitRequestFullscreen().then(() => {
        //     props.handleClose();
        //   });
        // } else if (elem.msRequestFullscreen) { // IE/Edge
        //   elem.msRequestFullscreen().then(() => {
        //     props.handleClose();
        //   });
        // }
        const elem = document.documentElement; // or any other element

        const requestFullscreen = elem.requestFullscreen || elem.mozRequestFullScreen || elem.webkitRequestFullscreen || elem.msRequestFullscreen;
    
        if (requestFullscreen) {
          requestFullscreen.call(elem);
          props.handleClose();
        }
      };
    
  return (
    <div>
 <Modal className='modal_style' show={props.show} >
        <Modal.Header >
          <Modal.Title>Fixio Bingo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className='desctext'> FullScreen Enabled to Game Play</p>
         </Modal.Body>
        <Modal.Footer>
          <button  className='enterbtn' onClick={() => enterFullScreen() } >
            Enter
          </button>
         
        </Modal.Footer>
      </Modal>


    </div>
  )
}
