import React, { useEffect, useState } from 'react'
import Bingoboard from './Bingoboard'
import Balls from './Balls'

export default function ScreenMidsection(props) {
 const [ballboard, setBallboard] = useState(false)
  useEffect(()=>{
      if(props.start){
        setTimeout(() => {
          setBallboard(true)
        }, 2000);
      }
   

  },[props.start])

  return (
    <div className='screen_Midsection'>
        <div>
          {ballboard && <> <Balls/>
          <Bingoboard/>
          </>

}
            
        </div>
    </div>
  )
}
