import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Screentype } from '../Redux/action';

const DeviceOrientation = () => {
    const dispatch = useDispatch();
  const [orientation, setOrientation] = useState(
    window.matchMedia('(orientation: portrait)').matches ? 'portrait' : 'landscape'
  );

  useEffect(() => {
    const mediaQuery = window.matchMedia('(orientation: portrait)');

    const handleOrientationChange = (event) => {
      setOrientation(event.matches ? 'portrait' : 'landscape');
    };

    mediaQuery.addEventListener('change', handleOrientationChange);

    dispatch(Screentype({
        screentype: orientation,
    }),
    
      );
      localStorage.setItem('screentype', orientation);
    return () => mediaQuery.removeEventListener('change', handleOrientationChange);
  }, []);

  return orientation;
};

export default DeviceOrientation;