import React from 'react'
import { useSelector } from 'react-redux';

export default function ScreenRightsection() {
  const data = useSelector((state) => state.admin)
  console.log(data.ballrelease,"datadata");
  const bingoboard = [
    { B: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15], },
    { I: [16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30] },
    { N: [31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45] },
    { G: [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60] },
    { O: [61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75] }
];
  return (
    <div className='screen_Rightsection'>
      
        <div className='listnums'>
         
            <div className='bingolistnum'>
              {/* <img src={rightboard} className='img-fluid rightboardimg' alt='rightboard' /> */}
            {bingoboard.map((column, index) => {
            const columnKey = Object.keys(column)[0]; 
            const columnValues = column[columnKey];

            return (
                <ul className='bingolist'>
                  {columnValues.map((value) => (
                    // <li className='listnum' key={value}>{value}</li>
                    <li className={` ${data.ballrelease.includes(value) && 'active'} listnum`} key={value}>{value}</li>
                    
                  ))}
                </ul>
            );
          })}
           
          </div>
            
        </div>
    </div>
  )
}
