import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { isMobile } from 'react-device-detect';
import Mobilefullscreen from '../modal/mobilefullscreen';
export default function Login() {
  const [show, setShow] = useState(isMobile ? true : false);
    const navigate = useNavigate()
    const playgame = ()=>{
        navigate('/Fixio-Bingo',{ state: { musicenable: true } })
    }
  return (
    <>
    <div className='loginpage'>

      <div className='loginsec d-flex justify-content-center align-items-center h-100'>
      <button className='playbtn' onClick={() => {playgame()}}>play</button>
      </div>
     
  
     
    </div>
    {show && <Mobilefullscreen show={show} handleClose={() => setShow(false)}/>}

    </>
    
  )
}
