const Images ={
    cloudimg:require("../images/cloud.png"),
    balls:require("../images/Balls.png"),
    seagullwebp:require("../images/seagull.webp"),
    lefttreelayer:require("../images/lefttree_layer.png"),
    lefttreelayerfront:require("../images/lefttree_front.png"),
    rightbtm:require("../images/right_btm.png"),
    rightbtmleave:require("../images/rightleaves.png"),
    righttopleave:require("../images/righttop.png"),
    fullscreenicon:require("../images/fullscreen.png"),
    puzzleicon:require("../images/bingobtn_puzzle.png"),
    rotate:require("../images/rotate.webp"),
    stamp:require("../images/bingopunch.png"),
    righttreeanim:require("../images/righttreeanim.webp"),
    mark:require("../images/mark.webp"),
    winbingo:require("../images/winbingo.png"),
    diagonalcut:require('../images/cutbingo.webp'),
    linecut:require('../images/linefire.webp'),
    countimage:require('../images/countboard.png'),
}
    
      
export default Images;