import React, { useState, useEffect } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css'; // Import default styles

const CircularTimer = ({ duration }) => {
  const [timeRemaining, setTimeRemaining] = useState(duration);
  const [percentage, setPercentage] = useState(100);

  useEffect(() => {
    const interval = 1000; // Update every second
    const totalDuration = duration;

    // Calculate percentage
    const calculatePercentage = (time) => (time / totalDuration) * 100;

    setTimeout(() => {
      
    const timer = setInterval(() => {
        setTimeRemaining((prevTime) => {
          if (prevTime <= 0) {
            clearInterval(timer);
            return 0;
          }
          const newTime = prevTime - interval;
          setPercentage(calculatePercentage(newTime));
          return newTime;
        });
      }, interval);
  
      return () => clearInterval(timer); // Cleanup on component unmount  
    }, 5000);

  }, [duration]);

  return (

      <CircularProgressbar
        value={percentage}
        strokeWidth={5}
        // text={`${Math.ceil(timeRemaining / 1000)}s`}
        styles={buildStyles({
          pathColor: '#f2b90c',
          textColor: '#f88',
          trailColor: '#000',
          backgroundColor: '#000',
          pathTransitionDuration: 7,
 
        })}
      />

  );
};

export default CircularTimer;
