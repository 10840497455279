
import React, { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import Lottie from 'lottie-react';
import Images from "../assest/images/images.js"
import righttreejson from '../assest/json/rightree.json'
// import sealayer from '../assest/json/sealayer.json';
import dolphin from '../assest/json/dolphin.json'
import waves from '../assest/json/waves.json'

import bgsound from '../assest/audio/BG_Sound.mp3';
import Seagull_Sound from '../assest/audio/Seagull_Sound.mp3'
import { useLocation } from 'react-router-dom';
import ScreenLeftsection from '../components/ScreenLeftsection';
import ScreenMidsection from '../components/ScreenMidsection';
import ScreenRightsection from '../components/ScreenRightsection';

export default function App() {
  const sectionRef = useRef(null);
  const location = useLocation();
  const [scroll, setScroll] = useState(false);
  const [time, setTime] = useState(10);

  const handleTimeChange = (change) => {
    if (time + change >= 0) {
      setTime(time + change);
    }
  };

  useEffect(() => {
    let interval;
    if (true) {
      interval = setInterval(() => {
        window.scrollBy(0, 20);
      }, 10);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [scroll, time]);

  useEffect(() => {
    function stopScrolling(e) {
      setScroll(false);
    }
    window.addEventListener("pointerdown", stopScrolling);
    window.addEventListener("mousewheel", stopScrolling);
    window.addEventListener("touchmove", stopScrolling);

    return () => {
      window.removeEventListener("pointerdown", stopScrolling);
      window.removeEventListener("mousewheel", stopScrolling);
      window.removeEventListener("touchmove", stopScrolling);
    };
  }, []);

  return (
   <>

<div className='bingohome'>
      {/* {isMobile &&
      <img onClick={toggleFullScreen} ref={fullref} src={Images.fullscreenicon} className='img-fluid fullscreenbtn' alt='fullicon'/>
} */}
      {/* <button onClick={toggleFullScreen}  className='fullscreenbtn'>
        {isFullScreen ? 'Exit Full Screen' : 'Enter Full Screen'}
      </button> */}
      <div className='logocloud'>
        {/* <Lottie
              animationData={cloudlogo}
              loop={false}
              className="cloudjson"
            /> */}
        <div className='imgsec position-relative'>
          <img src={Images.cloudimg} className='img-fluid cloudimg' alt='cloud' />
          <img src={Images.balls} className='img-fluid ballimg' alt='cloud' />
        </div>
        <div style={isMobile ? { height: '460px' } : { height: '650px' }} ></div>
      </div>
      <div className='skybtmsec position-relative'>
        <div style={isMobile ? { height: '120px' } : { height: '200px' }} ></div>
        <div
          ref={sectionRef}
          className='gamesec'>
          {/* <button onClick={toggleFullScreen} className='mt-5'>
        {isFullScreen ? 'Exit Full Screen' : 'Enter Full Screen'}
      </button> */}
          {/* {lottieenable && */}
          {/* <Lottie
              animationData={Treejson}
              loop={true}
              className="leftree"
              
            /> */}
            <img src={Images.seagullwebp} className='img-fluid seagulljosn' alt='seagulljosn'/>
          {/* {lottieenable && */}
           <>
            {/* <Lottie
              animationData={seagull}
              loop={true}
              className="seagulljosn"

            /> */}
            <Lottie
              animationData={waves}
              loop={true}
              className="wavejson"

            />
             <Lottie
              animationData={waves}
              loop={true}
              className="wavejson1"

            />
             <Lottie
              animationData={waves}
              loop={true}
              className="wavejson2"

            />
             {/* <Lottie
              animationData={sealayer}
              loop={true}
              className="sealayer"

            /> */}
             <Lottie
              animationData={dolphin}
              loop={true}
              className="dolphin"

            />
          </>
          {/* } */}
          <div className='left_treesec'>
            <div className='inside_sec'>

              <img src={Images.lefttreelayer} className='img-fluid lefttreelayer' alt='treelayer' />
              {/* <Lottie
              animationData={lefttreejson}
              loop={true}
              className="lefttreelayer"

            /> */}
              
              <div className='lefttree_frontsec'>
                <div className='chimesec'>
                {/* <Lottie
              animationData={lefttreejson}
              loop={true}
              className="lefttree_front"

            /> */}
              <img src={Images.lefttreelayerfront} className='img-fluid lefttree_front' alt='lefttree_front' />
              </div>
              </div>
              {/* <img src={chimes} className='img-fluid chimes' alt='chimes' /> */}
              
              
            </div>
          </div>
          <div className='right_treesec'>
            <div className='inside_sec'>
              {/* <Lottie
                animationData={righttreejson}
                loop={true}
                autoPlay={lottieenable}
                className="righttree"

              /> */}
                <img src={Images.righttreeanim} className='img-fluid righttree' alt='righttree' />
                <img src={Images.righttreeanim} className='img-fluid righttree1' alt='righttree1' />
              {/* <Lottie
                animationData={righttreejson}
                loop={true}
                autoPlay={lottieenable}
                className="righttree1"

              /> */}
              <img src={Images.rightbtm} className='img-fluid rightbtm' alt='treelayer' />
              <img src={Images.rightbtmleave} className='img-fluid rightbtmleave' alt='lefttree_front' />
              <img src={Images.righttopleave} className='img-fluid righttopleave' alt='righttopleave' />
            </div>
          </div>
      {true &&
             <div className='screenlayersec'>
              <ScreenLeftsection/>
              <ScreenMidsection start={true} />
              <ScreenRightsection start={true}/>
            
          </div>
}


          {/* } */}
        </div>
      </div>
    </div>
   </>
  );
}
