import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import { isMobile } from 'react-device-detect';
import './App.css';
import Home from './pages/Home';
import Login from './pages/Login';
import Checkup from "./Hooks/checkup";
import toast, { Toaster } from 'react-hot-toast';
import SocketContext from './Content/socketContext';
import { socket } from './Config/socketConnectivity';
import DeviceOrientation from './Hooks/OrientationCheck';
import { useSelector } from 'react-redux';
import Warning from './pages/Warning';
import Homecheck from './pages/Homecheck';


function App() {
  const Data = useSelector((state) => state.admin)
  const device = DeviceOrientation();

  return (
    <>
      <div><Toaster /></div>
      
      <SocketContext.Provider value={{ socket }}>
        <Router>
        <Routes>
          {  device == "landscape" ? <><Route exact path="/" element={<Login/>}/> 
          
          <Route exact path="/Fixio-Bingo" element={<Home/>}/>
        <Route exact path="/home" element={<Homecheck/>}/>
        <Route exact path="/checkup" element={<Checkup/>}/>
        </>
          :  <Route exact path="/" element={<Warning/>}/>}
        {/* <Route exact path="/Fixio-Bingo" element={<Home/>}/>
        <Route exact path="/home" element={<Homecheck/>}/>
        <Route exact path="/checkup" element={<Checkup/>}/> */}
      </Routes>
        </Router>
      
      </SocketContext.Provider>
    </>
  );
}

export default App;
