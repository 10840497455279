import { config } from "../Config/env";
import { axiosFunc } from "./UserHelper";
import axios from "axios";


const getAuthToken = () => {
    try {
        let token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6InZlbmthdGVzaCIsImVtYWlsIjoidmVua2F0ZXNoamptYXRpY3pAZ21haWwuY29tIiwiX2lkIjoiNjY5YTAwYzM3MmNkZjRlYjU3ZWMwNjVkIiwiaWF0IjoxNzIyMjI2NjI3LCJleHAiOjE3MjIzMTMwMjd9.Yvb-5XI5VLAuQLfNnglZnImDPX75so_cDj5Zr_iRCks";
        console.log("tokendata", token)
        if (token != '' && token != null && token != undefined) {
            return "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6InZlbmthdGVzaCIsImVtYWlsIjoidmVua2F0ZXNoamptYXRpY3pAZ21haWwuY29tIiwiX2lkIjoiNjY5YTAwYzM3MmNkZjRlYjU3ZWMwNjVkIiwiaWF0IjoxNzIyMjI2NjI3LCJleHAiOjE3MjIzMTMwMjd9.Yvb-5XI5VLAuQLfNnglZnImDPX75so_cDj5Zr_iRCks"
        }

    } catch (err) {
        console.log("getAuthTokenerrrrrr", err)
    }
}

axios.defaults.headers.common['Authorization'] = getAuthToken();

export const UserRegisterHooks = async () => {
    try {
        let reqData = {
            method: "post",
            url: `${config.BACKURL}userapi/user/register`,
          }; 
          var resp = await axiosFunc(reqData, "UserRegisterHooks");
          console.log("UserRegisterHooks_Resp", resp)
    } catch (e) {
        console.log("UserRegisterHooks_Err", e)
    }
}



export const UserLoginHooks = async () => {
    try {
        let reqData = {
            method: "post",
            url: `${config.BACKURL}userapi/user/login`,
          }; 
          var resp = await axiosFunc(reqData, "UserLoginHooks");
          console.log("UserLoginHooks_resp", resp)
    } catch (e) {
        console.log("UserLoginHooks_err", e)
    }
}


export const UserPlayGameHooks = async (data) => {
    try {
        console.log("UserPlayGameHooks_Req", data)
        let reqData = {
            method: "post",
            url: `${config.BACKURL}userapi/user/userplaygame`,
            data : {data}
          }; 
          var resp = await axiosFunc(reqData, "UserPlayGameHooks");
          console.log("UserPlayGameHooks_resp", resp);
          return resp;
    } catch (e) {
        console.log("UserPlayGameHooks_err", e)
    }
}


export const getUserPlayingGameHooks = async (data) => {
    try {
        let reqData = {
            method: "Get",
            url: `${config.BACKURL}userapi/user/getuserplayinggamedata`,
            params : {data},
          }; 
          var resp = await axiosFunc(reqData, "getUserPlayingGameHooks");
          console.log("getUserPlayingGameHooks_resp", resp);
          return resp;
    } catch (e) {
        console.log("getUserPlayingGameHooks_err", e)
    }
}