import { createSlice } from "@reduxjs/toolkit";


export const adminSlice = createSlice({
  name: 'admin',
  initialState: {
    screentype: localStorage.getItem('screentype') === undefined ? 'portrait' : localStorage.getItem('screentype'),
    ballrelease:[],
    ballactive:[],
    
  },
  reducers: {
    Statuses: (state, action) => {
      return (
        { ...state, ...action.payload }
      )
    },
    Screentype: (state, action) =>{
      state.screentype = action.payload
    },
    Ballrelease: (state, action) =>{
      state.ballrelease.push(action.payload.ballrelease);
    },
    Ballactive: (state, action) =>{
      state.ballactive.push(action.payload.ballactive);
    },
 
  }

})
export const { Screentype, Statuses,Ballrelease,Ballactive } = adminSlice.actions
export default adminSlice.reducer