import React from 'react'
import menuicon from '../assest/images/menu.png'
import Images from '../assest/images/images'

export default function ScreenLeftsection() {
  return (
    <div className='screen_Leftsection'>
        <div>
           <img src={menuicon} className='menuicon' />

           <div className='countsec'>
            <img src={Images.countimage} className='img-fluid countimg' alt='countboard' />
            <p className='bingotext'>BINGO</p>
            <p className='bingonum'>25</p>

           </div>
            
        </div>
    </div>
  )
}
