//import lib
import io from 'socket.io-client'
import jwt_decode from "jwt-decode";

import { config } from "../Config/env";


const socket = io(config.BACKURL);
console.log("socket data enabled", socket);

socket.on('error', function () {
    console.log("Sorry, there seems to be an issue with the connection! socket data");
});


socket.on('connect_error', function (err) {
    console.log("connect failed socket data " + err);
});


socket.on('connection', function () {
    console.log('Client Connected socket data enabled', socket?.id);
    const token = localStorage.getItem("accessToken");
    if (token) {
        createSocketUser()

    }
});



// Listen for the connect event
socket.on('connect', () => {
    console.log('Socket connected: enabled', socket.id);
});

// Listen for the disconnect event
socket.on('disconnect', (reason) => {
    console.log('Socket disconnected: enabled', reason);
});



// Example of checking connection status
if (socket.connected) {
    console.log('Socket is connected enabled');
} else {
    console.log('Socket is not connected enabled ');
}



socket.on('connect', function () {
    const token = localStorage.getItem("accessToken")
    if (token) {
        const decoded = jwt_decode(token);
        console.log("socket decode_userdata", decoded?._id);
        socket.emit('LOGINUSER', decoded?._id)

    }

    
});



const createSocketUser = (userId) => {
    socket.emit('LOGINUSER', userId)
}


const EmitSeconds = (gamedata) => {
    console.log('emitseconds', gamedata)
    socket.emit('EMITSECONDS', gamedata)
}




export {
    socket,
    createSocketUser,
    EmitSeconds
}