import {configureStore}  from '@reduxjs/toolkit'
import adminReducer from './action'


export const store = configureStore({
    reducer: {
        admin:adminReducer
    }
})



export default store