import React from 'react'
import Images from "../assest/images/images.js"
import { isMobile } from 'react-device-detect';
export default function Warning() {
  return (
    <div className='warningpage'>
        {isMobile ?
           <div>
           <img src={Images.rotate} className='img-fluid rotateimg' alt='rotateimg' />
           <p className='rotatetext'>FOR THE BEST EXPERIENCE ROTATE YOUR PHONE</p>
       </div>
       :
       <div>
       {/* <img src={Images.rotate} className='img-fluid rotateimg' alt='rotateimg' /> */}
       <p className='rotatetext'>FOR THE BEST EXPERIENCE ROTATE YOUR PHONE</p>
   </div>
        }
     
     

    </div>
  )
}
