import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
export default function Closewarn(props) {

  const handleCloseTab = () => {
    // const confirmClose = window.confirm("Are you sure you want to close this tab?");
    // if (confirmClose) {
    //   window.open('', '_self').close();
    // }
    const newWindow = window.open('https://www.google.com', '_self');
    newWindow.close();
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) { // Firefox
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) { // Chrome, Safari and Opera
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) { // IE/Edge
      document.msExitFullscreen();
    }

  };
  return (
    <div>
 <Modal className='modal_style closemodal' show={props.show} >
        <Modal.Header >
          <Modal.Title>Fixio Bingo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className='desctext'> Close Game</p>
         </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" className='enterbtn' >
            Close Game
          </Button> */}
          <button className='btn closebtn' onClick={() =>  handleCloseTab()} > Close Game</button>
          <button className='btn canclebtn' onClick={() => props.handleClose()}>Cancel</button>
         
        </Modal.Footer>
      </Modal>


    </div>
  )
}
