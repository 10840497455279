
import React, { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import Lottie from 'lottie-react';
import Images from "../assest/images/images.js"
import righttreejson from '../assest/json/rightree.json'
// import sealayer from '../assest/json/sealayer.json';
import dolphin from '../assest/json/dolphin.json'
import waves from '../assest/json/waves.json'

import bgsound from '../assest/audio/BG_Sound.mp3';
import Seagull_Sound from '../assest/audio/Seagull_Sound.mp3';
import Dolphin_Sound from '../assest/audio/Dolphin_Sound.mp3';
import { useLocation } from 'react-router-dom';
import ScreenLeftsection from '../components/ScreenLeftsection';
import ScreenMidsection from '../components/ScreenMidsection';
import ScreenRightsection from '../components/ScreenRightsection';
import Closewarn from '../modal/Closewarn.jsx';
import useUnloadWarning from '../Hooks/Reloadwarning.js';
import Warnmodal from '../modal/Warnmodal.jsx';
// import lefttreejson from '../assest/json/lefttree_final.json'
const Home = () => {
  useUnloadWarning();
  const sectionRef = useRef(null);
  const location = useLocation();
  const { musicenable } = location.state || {};
  const [lottieenable, setLottieenable] = useState(false);
  const [boardenable, setBoardenable] = useState(false)
  const [audioPlay, setAudioPlay] = useState(true);
  const [dolphinstart,setDolphinstart] =useState(false)
  const [closemodal,setClosemodal] =useState(false)
  const [scrollEnd, setScrollEnd] = useState();
  const [timeTaken, setTimeTaken] = useState();
  const audioRef = useRef(new Audio(bgsound));
  const seagullRef = useRef(new Audio(Seagull_Sound));
  const dolphinRef = useRef(new Audio(Dolphin_Sound));
  useEffect(() => {
    
        const audio = audioRef.current;
        const seagullaudio = seagullRef.current;
        audio.loop = true;
        seagullaudio.loop = false;
        if (audioPlay && musicenable ) {
          audio.play();
          seagullaudio.play();
        } else {
          audio.pause();
          audio.currentTime = 0;
        }
      
      }, [audioPlay]);
      
    
  useEffect(() => {
   

    const scrollfull = (event) => {
      sectionRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    window.addEventListener('resize', scrollfull);
    console.log("checkscroll",window.innerHeight);
    return () => {
      window.removeEventListener('resize', scrollfull);
    };
  }, [window.innerHeight])
  // useEffect(() => {


  //   let scrollInterval = setInterval(() => {
  //     let currentPosition = window.scrollY;
  //     const windowHeight = window.innerHeight;
  //     const documentHeighted = document.documentElement.scrollHeight;
  //     let documentHeight = document.body.scrollHeight - window.innerHeight;
  //     if (currentPosition + windowHeight >= documentHeighted) {
  //       if (currentPosition) {
  //         setScrollEnd(true);
  //       }
  //     }
  //     window.scrollBy({
  //       top:isMobile ? 40 : 75,
  //       behavior: 'smooth'
  //     });
  //     if (currentPosition >= documentHeight) {
  //       clearInterval(scrollInterval); // Stop scrolling
  //     }
  //   }, isMobile ? 50 : 50);

  //   setTimeout(() => {
  //     // clearInterval(scrollInterval);
  //     // sectionRef.current.scrollIntoView({ behavior: 'smooth' });
  //     const lockScroll = (event) => {
  //       event.preventDefault();
  //     };

  //     window.addEventListener('scroll', lockScroll);
  //     window.addEventListener('wheel', lockScroll, { passive: false });
  //     window.addEventListener('touchmove', lockScroll, { passive: false });


  //   }, isMobile ? 10000 : 8000); // Stop after 10000ms (10 seconds)

  //   // Clean up function to clear the interval when component unmounts
  //   return () => {
  //     clearInterval(scrollInterval);
  //   };
  // }, []);

  const [isFullScreen, setIsFullScreen] = useState(false);

  const enterFullScreen = () => {
    const elem = document.documentElement; // You can also target a specific element
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) { // Firefox
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) { // Chrome, Safari and Opera
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { // IE/Edge
      elem.msRequestFullscreen();
    }
    setIsFullScreen(true);
  };

  const exitFullScreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) { // Firefox
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) { // Chrome, Safari and Opera
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) { // IE/Edge
      document.msExitFullscreen();
    }
    setIsFullScreen(false);
  };

  const toggleFullScreen = () => {
    if (!isFullScreen) {
      enterFullScreen();
    } else { 
      exitFullScreen();
    }
  };
  const fullref = useRef(null);
  
  useEffect(() => {
    if (musicenable){
      setTimeout(() => {
        setLottieenable(true)
  
      }, 1500);
      const dolphinaudio = dolphinRef.current;
      dolphinaudio.loop = false;

      setInterval(() => {
        dolphinaudio.play();
      }, 1700);
      setTimeout(() => {
        setBoardenable(true)
  
      }, 2000);
 
  
    }
  },[scrollEnd])
  return (
    <div className='bingohome'>
       
      {closemodal &&   <Closewarn show={closemodal} handleClose={() => setClosemodal(false)}/>}
   
      {isMobile &&
      <img onClick={()=>setClosemodal(true)} ref={fullref} src={Images.fullscreenicon} className='img-fluid fullscreenbtn' alt='fullicon'/>
}
      {/* <button onClick={toggleFullScreen}  className='fullscreenbtn'>
        {isFullScreen ? 'Exit Full Screen' : 'Enter Full Screen'}
      </button> */}
      {/* <div className='logocloud'>
       
        <div className='imgsec position-relative'>
          <img src={Images.cloudimg} className='img-fluid cloudimg' alt='cloud' loading='eager' />
          <img src={Images.balls} className='img-fluid ballimg' alt='cloud' loading='eager' />
        </div>
        <div style={isMobile ? { height: '460px' } : { height: '650px' }} ></div>
      </div> */}
      <div className='skybtmsec position-relative'>
        {/* <div style={isMobile ? { height: '120px' } : { height: '200px' }} ></div> */}
        <div
          ref={sectionRef}
          className='gamesec'>
          {/* <button onClick={toggleFullScreen} className='mt-5'>
        {isFullScreen ? 'Exit Full Screen' : 'Enter Full Screen'}
      </button> */}
          {/* {lottieenable && */}
          {/* <Lottie
              animationData={Treejson}
              loop={true}
              className="leftree"
              
            /> */}
            <img src={Images.seagullwebp} className='img-fluid seagulljosn' alt='seagulljosn' />
          {lottieenable && <>
            {/* <Lottie
              animationData={seagull}
              loop={true}
              className="seagulljosn"

            /> */}
            <Lottie
              animationData={waves}
              loop={true}
              className="wavejson"

            />
             <Lottie
              animationData={waves}
              loop={true}
              className="wavejson1"

            />
             <Lottie
              animationData={waves}
              loop={true}
              className="wavejson2"

            />
             {/* <Lottie
              animationData={sealayer}
              loop={true}
              className="sealayer"

            /> */}
             <Lottie
              animationData={dolphin}
              loop={true}
              className="dolphin"
              onSegmentStart={()=> setDolphinstart(true)}
              

            />
          </>
          }
          <div className='left_treesec'>
            <div className='inside_sec'>

              <img src={Images.lefttreelayer} className='img-fluid lefttreelayer' alt='treelayer'  />
              {/* <Lottie
              animationData={lefttreejson}
              loop={true}
              className="lefttreelayer"

            /> */}
              
              <div className='lefttree_frontsec'>
                <div className='chimesec'>
                {/* <Lottie
              animationData={lefttreejson}
              loop={true}
              className="lefttree_front"

            /> */}
              <img src={Images.lefttreelayerfront} className='img-fluid lefttree_front' alt='lefttree_front'  />
              </div>
              </div>
              {/* <img src={chimes} className='img-fluid chimes' alt='chimes' /> */}
              
              
            </div>
          </div>
          <div className='right_treesec'>
            <div className='inside_sec'>
              {/* <Lottie
                animationData={righttreejson}
                loop={true}
                autoPlay={lottieenable}
                className="righttree"

              /> */}
                <img src={Images.righttreeanim} className='img-fluid righttree' alt='righttree'  />
                <img src={Images.righttreeanim} className='img-fluid righttree1' alt='righttree1'  />
              {/* <Lottie
                animationData={righttreejson}
                loop={true}
                autoPlay={lottieenable}
                className="righttree1"

              /> */}
              <img src={Images.rightbtm} className='img-fluid rightbtm' alt='treelayer'  />
              <img src={Images.rightbtmleave} className='img-fluid rightbtmleave' alt='lefttree_front'  />
              <img src={Images.righttopleave} className='img-fluid righttopleave' alt='righttopleave'  />
            </div>
          </div>
      {boardenable &&
             <div className='screenlayersec'>
              <ScreenLeftsection/>
              <ScreenMidsection start={boardenable} />
              <ScreenRightsection start={boardenable}/>
            
          </div>
}


          {/* } */}
        </div>
      </div>
    </div>
  );
};

export default Home;