import React from 'react'
import Button from 'react-bootstrap/Button';
import {Link }  from 'react-router-dom'
import Modal from 'react-bootstrap/Modal';
import Images from '../assest/images/images';
export default function Winmodal(props) {
  return (
    <div>


<Modal className='modalwin_style' show={props.show} >
      
        <Modal.Body>
         <img src={Images.winbingo} className='win_bingo' alt='win_bingo' />
         <Link to={'/'} className='playagain_btn btn mt-3'  >
         <img src={Images.puzzleicon} className='img-fluid puzzleicon me-2' alt='puzzle' /> Play again
          </Link>
         </Modal.Body>
       
      </Modal>
    </div>
  )
}
