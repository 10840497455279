import crypto from "crypto-js";
import axios from "axios";
import { config } from "../Config/env";
import isEmpty from "is-empty";


export const axiosFunc = async (data, funcname) => {
    console.log("config.KEYconfig.KEY", config.KEY)
    console.log("reqData", data, funcname);
    try {
        let respdata = await axios(data);
        console.log("Resp?.data", respdata, respdata?.data, decrypt(respdata?.data), isEmpty(decrypt(respdata?.data)));
        if (respdata?.data) {
            respdata.data = respdata?.data ? isEmpty(decrypt(respdata?.data)) ? respdata?.data : decrypt(respdata?.data) : respdata?.data;
        }
        console.log("axiosFunc_data", respdata?.data)
        return respdata?.data;
    } catch (e) {
        console.log("error", e);
        return { success: "error", msg: null };
    }
};



export const encrypt = (data) => {
    try {
        if (typeof (data) == 'string') {
            return crypto.AES.encrypt(data, config.KEY).toString();
        }
        else {
            return crypto.AES.encrypt(JSON.stringify(data), config.KEY).toString();
        }
    }
    catch (error) {
        console.log("encrypt_errr", error)
        return false;
    }

}

export const decrypt = (data) => {
    try {
        var bytes = crypto.AES.decrypt(data, config.KEY);
        var decryptedData;
        try {
            decryptedData = JSON.parse(bytes.toString(crypto.enc.Utf8));
        }
        catch (err) {
            try {
                decryptedData = bytes.toString(crypto.enc.Utf8);
            }
            catch (dcpterr) {
                return false;
            }
        }
        return decryptedData;
    }
    catch (error) {
        console.log("decrypt_err", error)
        return false;
    }

}


