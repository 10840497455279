import React, { useEffect, useRef, useState } from 'react'
import puzzleicon from '../assest/images/bingobtn_puzzle.png'
import Images from '../assest/images/images';
import { useSelector } from 'react-redux';
import punchsound from '../assest/audio/punchsound.mp3';
import winsound from '../assest/audio/Victor_Sound.mp3'
import Winmodal from '../modal/Winmodal';
export default function Bingoboard() {
  const [rowind, setRowInd] = useState([]);
  const [selNum, setSelNumPos] = useState([]);
  const punchRef = useRef(new Audio(punchsound));
  const winRef = useRef(new Audio(winsound));
  const [pin, setPin] = useState([]);
  const [bingomatch,setBingomatch] = useState(true)
  const [winbingo,setWinbingo]= useState(false)
  const [stamp, setStamp] = useState()
  const [animcut,setAnimcut]=useState(false);
  const [winvalue,setWinvalue]=useState('')
  const data = useSelector((state) => state.admin)
  const bingoboard = [
    { B: [1, 2, 3, 4, 12] },
    { I: [21, 22, 23, 24, 25] },
    { N: [36, 37, 31, 39, 45] },
    { G: [52, 53, 54, 55, 60] },
    { O: [68, 69, 70, 71, 75] }
  ];

  useEffect(() => {
      RowsetIndex(); // function
  }, []);
  const RowsetIndex = () => {
    // Initialize an empty map to store the positions
    // you see the number_data console & you can understand this method.
    let boardval = bingoboard;
    const rows = [];
    for (let i = 0; i < 5; i++) {
      rows.push(boardval[0]['B'][i],
        boardval[1]['I'][i],
        boardval[2]['N'][i],
        boardval[3]['G'][i],
        boardval[4]['O'][i])
    }
    setRowInd(rows);
  }
 
  const checkbingo = (e,value,rowindex,columnKey,colindex)=>
  {
    console.log(rowindex,columnKey,colindex, "indexcheck");
    e.preventDefault()
    const punchaudio = punchRef.current;
    punchaudio.play();
    setStamp(value);  
    setTimeout(() => {
      setStamp(false); 
    }, 2000);
    
     if(data.ballactive.includes(value)){
      setPin(prev => [...prev, value]);
      let pos = rowind.indexOf(value);
      setSelNumPos([...selNum, pos])
      checkwin(selNum)
     }
     
        
  }
  const checkwin = (selNum) => {
    const matches = selNum.filter(element => [4,8,12,16,20].includes(element));
    console.log("winbingo",matches.length);
    if  (matches.length >= 4) {
      setBingomatch(false)
 
      
      
    }
    // const bingoboardbox=[{0:false, 1:false, 2:false, 3:false, 4:false},{0:false, 1:false, 2:false, 3:false, 4:false},{0:false, 1:false, 2:true, 3:false, 4:false},{0:false, 1:false, 2:false, 3:false, 4:false},{0:false, 1:false, 2:false, 3:false, 4:false}];

    // if (bingoboardbox[rowindex][colindex] == false ) {
    //   return true;
     
    // }
  }
  const winfun = () => {
    setAnimcut(true)
    setWinvalue([4, 8, 12, 16, 20])
    setTimeout(() => {
    
      setWinbingo(true)
    }, 1200);
    setTimeout(() => {
    
      setWinbingo(true)
      const winaudio = winRef.current;
      winaudio.play();
    }, 1200);

  }

  
  return (
     <div className='boardsection'>
    <div className={`${stamp && 'active'} bingoboard_style`} >
      <div className='listnums'>

        <div className="bingolistnum">
          {animcut && 
          <img src={Images.diagonalcut} className='img-fluid wincuting LeftDiagonal' alt='diagonalcut' />
           } 
            
          <button className='btn bingo_btn' disabled={bingomatch} onClick={()=>winfun()}><img src={puzzleicon} className='img-fluid puzzleicon' alt='puzzle' /> BINGO !</button>
          {/* <img src={rightboard} className='img-fluid rightboardimg' alt='rightboard' /> */}
          {bingoboard.map((column, rowindex) => {
            const columnKey = Object.keys(column)[0];
            const columnValues = column[columnKey];

            return (
              <ul className='bingolist'>
                {columnValues.map((value,index) => (
                  <li className='listnum' key={value}>
                    
                    <button  className={` ${(pin.includes(value) || value == "free" ) && 'active'} ${winvalue.includes(((index * 5) + rowindex)) && 'winvalue'} btn listbtn`}
                  onClick={(e) => checkbingo(e,value,index,columnKey,rowindex)}>{value == "free" ? "" : value}
                  {stamp == value && 
                  <img src={Images.mark} className='img-fluid stampimg' alt='stamp' />
          }
                  </button></li>
                ))}
              </ul>
            );
          })}

        </div>

      </div>



    </div>
   { winbingo && <Winmodal show={winbingo} handleClose={()=>setWinbingo(false)} />}
    </div>
  )
}

