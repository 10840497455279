import React, { useEffect, useRef, useState } from 'react'
import CircularTimer from './timer';
import { useDispatch } from 'react-redux';
import { Ballrelease, Ballactive } from '../Redux/action';

export default function Balls() {
  const listRef = useRef(null);
  const dispatch = useDispatch();
  const [activeindex, setActiveindex] = useState()
  const [activecount, setActivecount] = useState(1)
  const [intialwidth, setInitialWidth] = useState()
  const [newlist, setNewlist] = useState([])
  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     if (listRef.current) {
  //       listRef.current.scrollLeft += 100; // Adjust the value as needed
  //     }
  //   }, 5000); // 3 seconds interval

  //   return () => clearInterval(intervalId); // Cleanup interval on component unmount
  // }, []);

  const fetchData = () => {
    for (let i = 0; i < balllist.length; i++) {
      const ballnum = Object.values(balllist[i])[0]
      setTimeout(() => {
        setNewlist(prevlist => [...prevlist, balllist[i]])
        setActiveindex(i)
        dispatch(Ballactive({
          ballactive: ballnum
        }),
        )

      }, i * 13500)
      setTimeout(() => {

        dispatch(Ballrelease({
          ballrelease: ballnum
        }),
        )
      }, (i + 1) * 15500);

    }

  };

  useEffect(() => {
    fetchData();

  }, []);
  useEffect(() => {
    if (listRef.current) {

      const initialWidth = listRef.current.scrollWidth;
      setInitialWidth(initialWidth);
      const scrollStep = 0.20;


      const intervalId = setInterval(() => {
        if (listRef.current) {
          const { scrollLeft, clientWidth, scrollWidth } = listRef.current;
          const lastChild = listRef.current.lastElementChild;

          if (lastChild) {
            // Calculate the left position of the last child
            const lastChildLeft = lastChild.offsetLeft;
            // Calculate max scroll position to show the last child fully
            const maxScrollLeft = lastChildLeft - clientWidth + lastChild.offsetWidth;
            const stepAmount = scrollWidth * scrollStep;
            if (scrollLeft >= maxScrollLeft) {
              clearInterval(intervalId);
            } else {
              // if(activecount ==  1){
              //   console.log(activeindex,"active");
              //   setActivecount(activecount + 1)
              // }
              // else{
              listRef.current.scrollLeft += stepAmount; // Adjust the value as needed
              // }




            }
          }
        }
      }, 50);

      return () => clearInterval(intervalId); // Clean up the interval on component unmount
    }
  }, []);
  const scrollContainerRef = useRef(null);

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     if (listRef.current) {

  //     const initialWidth = listRef.current.scrollWidth;
  //     setInitialWidth(initialWidth);
  //       const { scrollLeft, clientWidth, scrollWidth } = listRef.current;
  //       listRef.current.scrollBy({
  //         left: 200, // Adjust the scroll distance
  //         behavior: 'smooth',
  //       });
  //     }
  //   }, 5000); // Scroll every 5 seconds

  //   // Clean up interval on component unmount
  //   return () => clearInterval(intervalId);
  // }, []);

  const [balllist, setBalllist] = useState([
    {
      B: 12
    },
    {
      N: 36
    },
    {
      I: 24
    },
    {
      G: 54
    },
    {
      N: 31
    },
    {
      O: 68
    },
    {
      G: 53
    },
    
    {

    },
    {

    },
    {

    },
    {

    },
    {

    },
    {

    },


  ])

  return (
    <div className='ballsec'>
      <div className='balltrackbg'></div>

      <div className='balltrack'>

        <div className="balllist"
          ref={listRef}
        >
          {/* {console.log(intialwidth, intialwidth / 10, "intialwidth")} */}
          <div style={{ marginLeft: `calc(${intialwidth}px - ${intialwidth / 7}px)` }}></div>
          {newlist.map((e, i) => (
            <>

              <div className={`list-item ${i == activeindex ? 'active' : ''}`}>
                {(typeof e === 'object' && Object.keys(e).length !== 0) &&
                  <>
                    <div className='img_sec'>
                      <div className='numsec'>
                        <p className='label mb-0'>{e.B ? "B" : e.I ? "I" : e.N ? "N" : e.G ? "G" : e.O ? "O" : ""}</p>
                        <p className='value mb-0'>{e.B || e.I || e.N || e.G || e.O}</p>
                      </div>



                  
                 
              
                    <img src={e.B ? require('../assest/images/Red_Ball.png') : e.I ? require('../assest/images/SkyBlue_Ball.png') : e.N ? require('../assest/images/Violet_Ball.png') : e.G ? require('../assest/images/Orange_Ball.png') : require('../assest/images/Red_Ball.png')} className='img-fluid ballimg' alt='ball' />
                  </div>
                  {activeindex == i &&

                  <div className='timer'>
                 
                   <CircularTimer duration={2000} />
                   </div>
                     
}
</>
  }
                </div>
            
            </>

          ))}

        </div>
      </div>






    </div>
  )
}
