import React, { useEffect, useState, useRef } from "react";
import crypto from "crypto-js"

import { encrypt } from "../Hooks/UserHelper";
import { GenerateBingoBoardHooks } from "../Hooks/UseGame";
import { UserPlayGameHooks, getUserPlayingGameHooks } from "../Hooks/UseUser";
import toast from "react-hot-toast";
import { EmitSeconds } from "../Config/socketConnectivity";

var testarr = [];
var secondinst = 0;
const Checkup = () => {

const [randomsec, setRandomSec] = useState({});
const [fullboard, setFullBoard] = useState([]);


const [count, setCount] = useState(0);
const [isActive, setIsActive] = useState(false);
const intervalIdRef = useRef(null);
const [records, setRecords] = useState([]);
const [boardval, setBoardVal] = useState([]);
const [randomsecarr, setRandomSecArr] = useState({});
const [selectedNum, setSelectedNum] = useState([]);
const [selectedNumPos, setSelectedNumPos] = useState([]);
const [indexMap, setIndexMap] = useState({});
const [isRunning, setIsRunning] = useState(false);
const [time, setTime] = useState(0);
const [gameId, setGameId] = useState("")


var max = 75;

// Initially check the user already start the game.
useEffect(() => {
    async function fetchData() {
        try {
            let reqData = {
                method: "Get",
                filter: "getuserplayingdata",
                accessToken: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6InZlbmthdGVzaCIsImVtYWlsIjoidmVua2F0ZXNoamptYXRpY3pAZ21haWwuY29tIiwiX2lkIjoiNjY5YTAwYzM3MmNkZjRlYjU3ZWMwNjVkIiwiaWF0IjoxNzIxODAwMTU2LCJleHAiOjE3MjE4ODY1NTZ9.Z0iIcW8yEUPZxKqKJxXQauRRaHgc0gJRj6I7CFHJt7o",
            }
            let data = encrypt(reqData);
            let resp = await getUserPlayingGameHooks(data);
            console.log("resp?.data?.status", resp, resp?.success == false && resp?.data == "Session has expired!")
            if (resp?.data?.status == true) {
                let rec = resp?.data?.records[0];
                setRecords(rec);
                setBoardVal(rec?.Board);
                setRandomSecArr(rec?.RandomSeconds);
                setSelectedNum(rec?.SelectedNum);
                setSelectedNumPos(rec?.SelectedNumPosition);
                setIndexMap(rec?.IndexMap);
                setCount(rec?.RandomSeconds?.length);
            } else if (resp?.success == false && resp?.data == "Session has expired!") {
                toast.error(`${resp?.data}`)
            }
            console.log("useEff_getUserPlayingGameHooks", resp)
        } catch (e) {
            console.log("useEff_getUserPlayingGameHooks_Err", e)
        }
    }
    fetchData()
    decrypt("U2FsdGVkX19UdvWxvjeoV/Hg3sQLHnSY3Bfwmp6JhOmqxlN8brM0VjLojQBsucckIdl+lPrWnzUS7u2EDsx/KvJIgQptnJHrDFaAV48Os55+reyRkwK+CJ0VvLWiBRCO8DejxIicXJJSZis6OZUh4qGBbtH9W81Z8vZfqOwk6E2Ig1FeiRD/3+/DzVpxuvMiRfIpHlfhBoRgF5onVtG2KK1FXFOJt1XY7SLT+nfCbtjS+E3HEsVMa476dvCS2JvKOxrJsok0c6iVu+NyJA66vOwTHSnlfH9hA5FIvIz09vMNffPtEa0OnHsU5kMwJRDSN2WL0v4ivxp/8FmwwjWnevVdc7O9j0DoFF1/KgvKHh8IR9jKggP5wbs/n8XFpMx9/wDHpqIN9rBYW4N3aCZFos5s/dychRb+AYI22BoqcIWyfTN3++ga7/VUfTkQ+FWZs3OfclnYTOo+RwLVbxQHnQ==")
}, []);



console.log("randomsecarrrandomsecarr", randomsecarr)

// Initially , Already i can played game that one condition true vise & I can stop the game.
useEffect(() => {
    async function fetchData() {
        try {
            let reqData = {
                method : "Get",
                filter: "getuserplayingdata",
                accessToken: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6InZlbmthdGVzaCIsImVtYWlsIjoidmVua2F0ZXNoamptYXRpY3pAZ21haWwuY29tIiwiX2lkIjoiNjY5YTAwYzM3MmNkZjRlYjU3ZWMwNjVkIiwiaWF0IjoxNzIxOTcxOTk3LCJleHAiOjE3MjIwNTgzOTd9.Ht2sadlhTcD5p5QjnjqTF4Y8PpsxLWKaa2xJz_RJF_c",
            }
            let data = encrypt(reqData);
           let resp = await getUserPlayingGameHooks(data);
           console.log("resp?.data?.status",resp)
           if(resp?.status == true) {


            // playgame enable after come this respond only , Because already we start the game, this api
            // goes and end the game 
            // let rec = resp?.records[0];
            // setRecords(rec);
            // setBoardVal(rec?.Board);
            // setRandomSecArr(rec?.RandomSeconds);
            // setSelectedNum(rec?.SelectedNum);
            // setSelectedNumPos(rec?.SelectedNumPosition);
            // setIndexMap(rec?.IndexMap);
            // setCount(rec?.RandomSeconds?.length);
           }
           console.log("useEff_getUserPlayingGameHooks", resp)
        } catch (e) {
            console.log("useEff_getUserPlayingGameHooks_Err", e)
        }
    }
    fetchData()
    // decrypt("U2FsdGVkX1+4EMSha58nw07ZNEPUSeNJ2R541BKnqO2cap8x2E5RrMYyNNbu+sijKPSes62qPM29q6VOr7khD281bDAQq9A3JfMqRWOSICsJncePlFaTe/lSqU4vvg/GTbHfl8uEE4+qvIBNFlTgVHblV6qCU0oPTBB1MXG3Yqwt+z5qR3THjh5MW4cNG3CVdNSqkzGCgmonLS5BaQEy5Nz+9l3Ust04ZjzYAzwurG6JCmtgqtNXKF2k0dLU4L3ZrFqPg/lcpoXEJ9FDRFfPc9FmHRWMaCN9mAGAtXyWX+sMkA3g3At3CkwQ89Xpkaj4U/B3xKi8FmetwjpaylRc7jFsFrrKgmCy/lZPVh1eVqQz6+OeWKoM136xGhTwNrU/oYGTKjLh5X2BK1/+Tlr53fS3VY6Fq0NZKHDdmgmxaKWgnffIMdWXSnLQ3BrFfQ50sJUdIX2FFvdRZOJgtB72hQ==")
}, []);



console.log("randomsecarrrandomsecarr", randomsecarr)

// Initially , Already i can played game that one condition true vise & I can stop the game.
useEffect(() => {
    async function fetchData() {
        try {
            let reqData = {
                method : "Get",
                filter: "getuserplayingdata",
                accessToken: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6InZlbmthdGVzaCIsImVtYWlsIjoidmVua2F0ZXNoamptYXRpY3pAZ21haWwuY29tIiwiX2lkIjoiNjY5YTAwYzM3MmNkZjRlYjU3ZWMwNjVkIiwiaWF0IjoxNzIyMjI2NjI3LCJleHAiOjE3MjIzMTMwMjd9.Yvb-5XI5VLAuQLfNnglZnImDPX75so_cDj5Zr_iRCks",
            }
            let data = encrypt(reqData);
        //    let resp = await getUserPlayingGameHooks(data);
        //    console.log("resp?.data?.status",resp)
        //    if(resp?.status == true) {


            // playgame enable after come this respond only , Because already we start the game, this api
            // goes and end the game 
            // let rec = resp?.records[0];
            // setRecords(rec);
            // setBoardVal(rec?.Board);
            // setRandomSecArr(rec?.RandomSeconds);
            // setSelectedNum(rec?.SelectedNum);
            // setSelectedNumPos(rec?.SelectedNumPosition);
            // setIndexMap(rec?.IndexMap);
            // setCount(rec?.RandomSeconds?.length);
        //    }
        //    console.log("useEff_getUserPlayingGameHooks", resp)
        } catch (e) {
            console.log("useEff_getUserPlayingGameHooks_Err", e)
        }
    }
    fetchData()
    decrypt("U2FsdGVkX1/RqjS0SiQM0MhkWg/0/8wojT1EuWlunx1AJLJ53rwuokHd7F2EdmOjk2N5SSJCHvkyvyDMVRBY+e37Dy0aigGxUeVtDegcEaTQ6n6sRSVBEXXdsbWgIQEySJ2bIOEOhwuk9cXeu8muvgUE7nBDgQm7K68mbyoVtFDF3qF6bTmmJmUDcfW66hi4+zM7K+SzbM31VExImY49cBNqMjhr5sLEFQs9XqVsj7gh+ISnYlTpBgsPYCWl0qkCKCg+YCH/MRZ3VtWaIcoNyA==")
}, []);



// Initially create a data, Bingo Fulldetails
    useEffect(() => {
        async function fetchData() {
            // GenerateBingoBoardHooks()
            // let reqData = {
            //     method: "Post",
            //     filter: "userplayinggame",
            //     accessToken: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6InZlbmthdGVzaCIsImVtYWlsIjoidmVua2F0ZXNoamptYXRpY3pAZ21haWwuY29tIiwiX2lkIjoiNjY5YTAwYzM3MmNkZjRlYjU3ZWMwNjVkIiwiaWF0IjoxNzIxNzk3NTYzLCJleHAiOjE3MjE4ODM5NjN9.VXHLQBrVOW95nRdnGOm1uj75-jRAkOnoRMsgQO1K_fk",
            //     randomlygenNum: randomsec,
            // }


            let reqData = {
                method: "Post",
                filter: "userstartplay",
                board: [
                    1, 16, 36, 59, 67,
                    9, 25, 31, 53, 64,
                    4, 18, 'FREE', 52, 70,
                    12, 29, 37, 54, 61,
                    10, 19, 44, 57, 71
                ],
                fullboard: bingofullboard(),
                accessToken: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6InZlbmthdGVzaCIsImVtYWlsIjoidmVua2F0ZXNoamptYXRpY3pAZ21haWwuY29tIiwiX2lkIjoiNjY5YTAwYzM3MmNkZjRlYjU3ZWMwNjVkIiwiaWF0IjoxNzIyMjI2NjI3LCJleHAiOjE3MjIzMTMwMjd9.Yvb-5XI5VLAuQLfNnglZnImDPX75so_cDj5Zr_iRCks",
            }
            console.log("send_reqDarta", reqData)
            let data = encrypt(reqData);
            let resp = await UserPlayGameHooks(data)
            console.log("Encrypt home data", resp);
            if (resp?.status == true) {
                setGameId(resp?.data?._id);
                setRandomSec(resp?.data?.RandomSeconds)
                setTimeout(() => {
                    setIsRunning(true)
                }, 2000);
                setTimeout(() => {
                    setIsRunning(false)
                }, 5000)
            }
        }
        fetchData()
        decrypt("U2FsdGVkX196fMxlSUZ7cdZBjxEWdnLOc9Y2AiY/TSyQ8jXkQz5BamJtUc3sz03RBT13qsHo1JIStlcnqrAL0gyN3j/Re9Coe3HrDJ5Tlt+sSsNUuzwB5MGmC/uRt+mGmUwaZy7xLXNLr7Pdy+9nN2deeAgQ3fpoeK8uqmwmdEK7jhiuWMZJTo6hk+fzkBO/FC1McZxCxvlgdPtfdR9qqao929SQGBg6P5QBz4W6ji8ymBHiaIXmjoMHjLdf4Yisv8PeIsfrTQ8O/nX11lv6eDvcxtYN6wQf/otH9ixyjo23pduebAp0/7TsYxAXqySMYvRYCmkiEfQRF93NIURSW1lLY4Lhm2u5j98Qop+EtVWsfcnGXDODgI6/74Y4wRkm8Zs+2/TIcRz00UX7S7Bv+gxWNZcwhW47FJMlIuGFUxdg5JDFJ7Dr9YNnPetRNV+9n+LZC0EWjHaDA33j/YlicA==")
    }, [randomsec]);







    useEffect(() => {
        // let lastranNumbers = randomsec[randomsec?.length - 1];
        let generateNum = 55;
        let board = bingofullboard()
        console.log(board);
        let result = getBoardAlphabets(board, generateNum);
        setRandomSec(result)
        generateNumberPool()
        setFullBoard(board);
        
        // for(let i=0; i < )
    }, []);


// we will get bingofullboard
    const bingofullboard = () => {
        try {
            let bingoboard = [
                { B: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15] },
                { I: [16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30] },
                { N: [31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45] },
                { G: [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60] },
                { O: [61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75] }
            ];
            return bingoboard;
        } catch (e) {
            console.log("bingofullboard_Errr", e)
        }
    }

    // When generate any kind of number(0-75), we will get a matchable alphabet. Ex : N - 38, O - 71
    const getBoardAlphabets = (bingoboard, genNum) => {
        try {/*  */
            for (let i = 0; i < bingoboard.length; i++) {
                let boardNum = bingoboard[i];
                console.log("boardNum", Object.keys(boardNum)[0], Object.values(boardNum)[0]);
                let boardarr = Object.values(boardNum)[0];
                if(boardarr.includes(genNum)) {
                    return { [Object.keys(boardNum)[0]] : genNum }
                }
            }
        } catch (e) {
            console.log("getBoardAlphabets_Errr", e);
        }
    }



    const decrypt = (data) => {
        try {
            var bytes = crypto.AES.decrypt(data, "FIXIOBINGO");
            var decryptedData;
            try {
                decryptedData = JSON.parse(bytes.toString(crypto.enc.Utf8));
            }
            catch (err) {

                try {
                    decryptedData = bytes.toString(crypto.enc.Utf8);

                }
                catch (dcpterr) {
                    return false;
                }
            }
            console.log("decryptedData home data", decryptedData)
            return decryptedData;
        }
        catch (error) {
            console.log("decrypt_err", error)
            return false;
        }

    }


    useEffect(() => {
        if (isActive) {
          intervalIdRef.current = setInterval(() => {
            setCount(prevCount => {
              if (prevCount >= 74) {
                clearInterval(intervalIdRef.current);
                setIsActive(false); // Stop the interval after 75 executions
                return prevCount;
              }
              return prevCount + 1;
            });
          }, 5000);
        } else {
          clearInterval(intervalIdRef.current);
        }
        return () => clearInterval(intervalIdRef.current);
      }, [isActive]);


      const generateNumberPool = (count = max) => {
        console.log("max numbers data", max);
        const pool = Array.apply(null, Array(count)).map((_, i) => i + 1);
        console.log("pool datasgal", pool);
        testarr = pool;
      };


    const handleStart = () => {
        setCount(0);
        setIsActive(true);
      };


    useEffect(() => {
        let emitdata = {
            gameId: gameId,

        }
        let timer;
        if (isRunning) {
            timer = setInterval(() => {
                console.log("prevTime", (prevTime) => prevTime + 1);
                setTime((prevTime) => prevTime + 1);
                secondinst = secondinst + 1;
                emitdata['seconds'] = secondinst;
                EmitSeconds(emitdata)
            }, 999);
        }
    
        // Clean up the interval when the component is unmounted or isRunning changes
        return () => clearInterval(timer);
      }, [isRunning]);

      
      const resetTimer = () => {
        setIsRunning(false);
        setTime(0);
      };
      


    return (
        <div>
        <p>Interval has been called {count} times</p>
        <button onClick={() => {handleStart()}} disabled={isActive}>
          Start Interval
        </button>
      </div>
    )

    // let elementsToRemove = record?.RandomSeconds
    // let result = testarr.filter((item) => !elementsToRemove.includes(item));
    // console.log("result respondata", result)
    // testarr = result;
}



export default Checkup;