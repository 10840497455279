import { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const useUnloadWarning = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const handleBeforeUnload = (event) => {
     
      const message = 'Are you sure you want to leave? Changes you made may not be saved.';
      
   
      event.preventDefault();
      event.returnValue = message; 
      

      return message;
    };

    const checkRefreshCount = () => {
      const refreshCount = sessionStorage.getItem('refreshCount');
      if (refreshCount) {
        if (parseInt(refreshCount, 10) >= 1) {
          sessionStorage.removeItem('refreshCount'); // Reset the counter
          navigate('/');
          window.location.reload(); 
        } else {
          sessionStorage.setItem('refreshCount', '1');
        }
      } else {
        sessionStorage.setItem('refreshCount', '0');
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    checkRefreshCount();

    
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [navigate]);



  return "";
};

export default useUnloadWarning;
